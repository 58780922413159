<template>
  <div class="breadcrumbs">
    <template
      v-for="(link, idx) in list"
      :key="idx"
    >
      <router-link :to="{ name: link.name }">
        <a>{{ link.name.split('-').join(' ') }}</a>
      </router-link>
      <img
        class="arrow-next"
        src="~@/assets/img/iconos/arrow-rigth.svg"
        alt="Flecha hacia la derecha"
      >
    </template>
  </div>
</template>

<script>
  /* eslint-disable vue/no-dupe-keys */
  import { toRefs } from 'vue'

  export default {
    props: {
      list: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    setup(props) {
      const { list } = toRefs(props)
      return {
        list,
      }
    },
  }
</script>

<style lang="scss">
  .breadcrumbs {
    text-align: left;
    padding-bottom: 1rem;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    align-content: flex-start;
    gap: 0.8rem;

    & > :nth-child(1) {
      font-weight: bold;
    }

    .arrow-next:last-child {
      display: none;
    }
  }
</style>
